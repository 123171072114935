@use 'src/styles/colors';

/*
 * Local Import
 */

/*
 * Style
 */
.notFoundContainer {
  background: colors.$blue-dark;
  color: colors.$cream;
  text-align: center;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2em;
  z-index: 1;
  padding-bottom: 3em;

  // Sparkles
  &::before {
    background-repeat: repeat;
    background-position: center;
    background-image: url('src/assets/images/common/sparkles.svg');
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .title {
    font-size: 1.5em;
    line-height: 1.2;
    margin-bottom: 1em;
  }

  p {
    display: inline;
  }

  img {
    margin-top: 5em;
    margin-bottom: 2em;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
  }
}
