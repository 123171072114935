.startpage-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  margin: 1em auto;

  h1 {
    margin: 1em auto;
  }

  .buttons-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .checkboxes {
    .input-container {
      display: flex;

      label {
        flex: 1;
        margin-left: 2em;
      }

      margin-bottom: 1em;
    }
  }
}
