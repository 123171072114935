@use 'src/styles/colors';

@import 'src/styles/animations';
@import 'src/styles/breakpoints';

div.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  main.mainContent {
    flex: 1;
    @include respond-to('l') {
      overflow: auto;
    }
  }
}
