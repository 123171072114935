$black: #151000;
$cream: #fffdd8;
$gray-dark: #323232;
$gray: #777;
$gray-little: #aaa;
$gray-light: #ccc;
$gray-very-light: #e7e7e7;
$gray-lighter: #f5f5f5;
$white: #fff;

// Blue
$blue: #234b78;
$blue-dark: #1b3b5e;
$blue-light: #bad1eb;

// Green
$green: #5e9d07;
$green-light: #ebf3e1;

// Green Water
$green-water: #0ac3a7;
$green-water-dark: #078975;
$green-water-light: #e6f9f6;

// Red
$red: #d83a37;
$red-light: #fbebeb;

// Yellow
$yellow: #ffb400;
$yellow-dark: #e3a000;
$yellow-light: #fff8ee;
$orange: #f38000;

// Minor colors
$plum: #79485d;
