@keyframes fade-effect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin fade-in($time: 1s) {
  animation: fade-effect $time;
  animation: fade-effect $time;
}
