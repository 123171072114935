@use 'reset';
@use 'colors';
@use 'sass:color';

@import 'breakpoints';
@import 'font';

html {
  body {
    font-family: Oclock, Verdana, Geneva, Tahoma, sans-serif;
    background: #fff;
    line-height: 1.2;
    color: #323232;
    letter-spacing: 0.04em;
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;

    .container {
      height: 100vh;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      .mainContent {
        flex: 1;
      }
    }

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    h2 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.2;
    }

    p {
      font-size: 1.2rem;
      text-align: justify;
      line-height: 1.1;
      letter-spacing: 0;
    }

    em {
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.1;
      font-style: italic;
      color: colors.$gray-light;
    }

    input,
    select,
    textarea,
    button {
      border: 0;
      color: #0ac3a7;
      display: block;
      font-family: Oclock, Verdana, Geneva, Tahoma, sans-serif;
    }

    a.link {
      text-decoration: underline;
      color: colors.$green-water;
      font-weight: normal;
    }
  }
}
