$breakpoints: (
  'xxs': '320px',
  'xs': '360px',
  's': '520px',
  'm': '720px',
  'l': '900px',
  'xl': '1060px',
  'xxl': '1200px',
  'xxxl': '1400px',
  'huge': '1600px',
  'full': '1920px',
  'fullXl': '2080px',
  'fullXxl': '2240px',
  'fullXxxl': '2400px',
) !default;

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Impossible de trouver le breakpoint `#{$breakpoint}`. "
        +"Les breakpoints disponibles sont : #{map-keys($breakpoints)}.";
  }
}
