@use 'src/styles/colors';
@use 'sass:color';

@import 'src/styles/breakpoints';

.Card {
  padding: 1em 1em 0;
  box-shadow: 0 0 24px -5px colors.$gray-light;
  border-radius: 0.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid colors.$gray-very-light;

  b {
    font-weight: bold;
  }

  &__title {
    font-weight: 900;
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }

  &__content {
    flex: 1;
  }

  &__footer {
    background-color: colors.$green-water;
    padding: 1em;
    margin: 0 -1em;
    color: colors.$white;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  // @include respond-to('xl') {
  //   &:active {
  //     background: colors.$yellow;
  //   }
  // }
}
