@font-face {
  font-family: Oclock;
  font-weight: 200;
  font-style: normal;
  src: url('src/assets/fonts/oclock-light.woff2') format('woff2');
}

@font-face {
  font-family: Oclock;
  font-weight: 400;
  font-style: normal;
  src: url('src/assets/fonts/oclock-regular.woff2') format('woff2');
}

@font-face {
  font-family: Oclock;
  font-weight: 400;
  font-style: italic;
  src: url('src/assets/fonts/oclock-regularitalic.woff2') format('woff2');
}

@font-face {
  font-family: Oclock;
  font-weight: 600;
  font-style: normal;
  src: url('src/assets/fonts/oclock-medium.woff2') format('woff2');
}

@font-face {
  font-family: Oclock;
  font-weight: 700;
  font-style: normal;
  src: url('src/assets/fonts/oclock-bold.woff2') format('woff2');
}

@font-face {
  font-family: Oclock;
  font-weight: 900;
  font-style: normal;
  src: url('src/assets/fonts/oclock-black.woff2') format('woff2');
}
