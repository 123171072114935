@use 'src/styles/colors';

.QuestionAnswers {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__answers--radio,
  &__answers--checkbox {
    padding: 1em;
  }

  &.answer-container {
    max-width: 800px;
    margin: 0 auto 2rem;
  }

  &__image {
    max-width: 100%;
  }

  .input-container {
    margin: 0.5em 0;
    align-items: stretch;

    label {
      display: flex;
      align-items: center;
    }

    &--radio,
    &--checkbox {
      box-shadow: 0 0 5px 0 colors.$gray-very-light;
    }

    &--text {
      align-items: flex-start;
      max-width: 300px;
    }
  }
}
