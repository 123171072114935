.rituels-list-page {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto 3em;
  padding: 0 2rem;

  .text-container {
    margin-bottom: 2em;
  }

  .rituels-list-container {
    display: flex;
    flex-direction: column;

    h2 {
      text-align: left;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: 1em;
    }

    .rituels-list {
      .rituels-list-cards {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;

        .Card {
          margin: 1em;
          min-width: 300px;
          width: 45%;
        }
      }
    }
  }

  p {
    margin-bottom: 1.2em;
  }
}
