@use 'src/styles/colors';
@use 'sass:color';

.Steps {
  .step {
    width: 4%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: solid color.change(colors.$black, $alpha: 0.2);
    border-width: 1px 0 0 1px;
    color: colors.$gray;
    background: colors.$gray-very-light;

    &:hover {
      background: colors.$gray-light;
    }
  }

  .step--passed {
    background: colors.$yellow;

    &:hover {
      background: colors.$yellow-dark;
      color: colors.$yellow-light;
    }
  }

  .step--done {
    background: colors.$green-water;

    &:hover {
      background: colors.$green-water-dark;
      color: colors.$green-water-light;
    }
  }

  .step--current {
    box-shadow: inset 0 0 0.7em color.change(colors.$black, $alpha: 0.3);
  }
}
