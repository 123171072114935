@use 'src/styles/colors';
@use 'sass:color';

@import 'src/styles/breakpoints';

.rituelpage {
  &-elapsed {
    margin-top: 1rem;
    color: colors.$gray;
    text-align: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-loading {
    margin: auto;
    padding: 0 2em 2em;
    max-width: 600px;

    .bloc {
      margin-top: 2em;
    }

    .bloc4 {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    }
  }

  &-title {
    margin: 1rem auto;
    padding: 1rem 5rem;
    max-width: 800px;
    font-size: 1.5em;
  }

  &-buttons {
    margin-bottom: 8em;
    display: flex;
    justify-content: center;

    @include respond-to('s') {
      flex-direction: column-reverse;
      align-items: stretch;
    }
  }

  &-steps {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: row wrap;
    @include respond-to('l') {
      overflow: auto;
      flex-wrap: nowrap;
    }

    // display: grid;
    // grid-template-columns: repeat(25, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    // grid-gap: 0;
  }
}
