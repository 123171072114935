@use 'src/styles/colors';

@import 'src/styles/animations';
@import 'src/styles/breakpoints';

header.mainHeader {
  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 1em;

  @include respond-to('l') {
    box-shadow: 0 0 24px -5px colors.$gray-light;
  }

  .logoutButton {
    position: fixed;
    top: 1em;
    right: 2em;
    font-size: 1em;
    font-weight: bolder;
    text-transform: uppercase;
    box-shadow: 0 0 10px -1px colors.$gray-very-light;
    border: 1px solid colors.$gray-very-light;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 1px 10px 0 colors.$gray-light;
    }

    @include respond-to('l') {
      position: static;
    }
  }

  div.logo {
    margin: 1.5em 0 0.5em;
    height: 2.5em;
    width: 10em;
    position: relative;

    // Logo
    &::before {
      content: '';
      background: url('src/assets/images/common/logo.svg') no-repeat;
      background-size: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    // Couleurs
    &::after {
      content: '';
      background: colors.$green-water;
      border-radius: 100%;
      width: 7.5%;
      height: 30%;
      position: absolute;
      top: 2%;
      left: 18.3%;
      z-index: 1;
      @include fade-in(0.4);
    }

    // Media Queries
    @include respond-to('m') {
      height: 2em;
      width: 8em;
    }

    div {
      color: colors.$green-water;
      font-size: 0.6em;
      font-weight: 900;
      line-height: 0.9;
      text-align: center;
      padding-left: 0%;
      text-transform: uppercase;

      // Media Queries
      @include respond-to('m') {
        text-align: right;
      }
    }
  }

  h1 {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    font-size: 2.5em;
    margin-top: 0.5em;
    line-height: 1;

    // Media Queries
    @include respond-to('s') {
      font-size: 1.5em;
      margin-top: 0.75em;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 1.5em;
    color: colors.$gray-light;
    letter-spacing: 0.02em;
    line-height: 1.3;

    // Media Queries
    @include respond-to('s') {
      font-size: 0.9em;
    }
  }
}
