@use 'src/styles/colors';

.Card--rituel {
  .Card__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 1em;
  }

  .Card__score {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;

    h4 {
      font-size: 1.2em;
      margin-top: 1em;
      text-transform: uppercase;
      font-weight: 900;
      border-top: 1px solid colors.$gray-very-light;
      padding-top: 1em;
    }

    .Card__grade {
      display: flex;
      flex-direction: row;

      .grade__title {
        font-weight: bold;
        flex: 1;
      }
    }

    .Card__decision {
      text-align: center;
      font-size: 1.2em;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}
