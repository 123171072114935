@use 'src/styles/colors';

/*
 * Local Import
 */

/*
 * Style
 */
.ResultRituel {
  max-width: 500px;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  b {
    font-weight: bold;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 900;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    margin-top: 2em;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > li {
      padding: 0.2em;
      margin: 0.2em 0;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__box {
    text-align: center;
    margin: 2em 0;
    box-shadow: 0 0 10px 0 colors.$gray-light;
    padding: 2em;
    color: white;
    width: 100%;
  }

  &__dates {
    background-color: colors.$green-water;
  }

  &__score {
    background-color: colors.$green-water;
  }

  &__grade {
    width: 100%;
    text-align: left;
  }

  &__average {
    font-size: 3em;
    font-weight: 900;
  }

  &__decision {
    text-align: left;
    background-color: colors.$green-water;

    &--success {
      background-color: colors.$green;
    }

    &--fail {
      background-color: colors.$red;
    }

    .reasons {
      margin: 1em 0;

      ul {
        & > li {
          width: 100%;
        }
      }
    }

    .decision {
      font-size: 2.5em;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
