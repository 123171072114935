@use 'src/styles/colors';
@use 'sass:color';

@import 'src/styles/breakpoints';

.Button {
  appearance: none;
  background: colors.$green-water;
  border: 0;
  border-bottom: 0.3em solid colors.$green-water-dark;
  border-radius: 40px;
  color: colors.$cream;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.12em;
  margin: 0.5em;
  padding: 0.5em 2em;
  text-align: center;
  text-shadow: 0 0 0.25em color.change(colors.$black, $alpha: 0.1);
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: color.change(colors.$green-water, $alpha: 0.9);
  }

  @include respond-to('xl') {
    &:active {
      background: colors.$green-water;
      border-width: 0.15em;
      transform: translateY(0.1em);
    }
  }

  &--secondary {
    background: colors.$yellow;
    border-bottom: 0.3em solid colors.$yellow-dark;

    &:hover,
    &:focus {
      background: color.change(colors.$yellow, $alpha: 0.9);
    }

    @include respond-to('xl') {
      &:active {
        background: colors.$yellow;
      }
    }
  }

  &:disabled {
    background: colors.$gray-light;
    border-bottom: 0.3em solid colors.$gray-dark;
    color: colors.$white;
    opacity: 0.7;
    cursor: not-allowed;
  }
}
