@use 'src/styles/colors';
@use 'sass:color';

@import 'src/styles/breakpoints';

.input-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
  max-width: 100%;
  margin-bottom: 1.5rem;

  @include respond-to('xl') {
    width: 100%;
  }

  label {
    display: block;
    font-weight: 400;
    letter-spacing: 0;
    padding-left: 0.25em;
    width: 100%;
  }

  &--checkbox,
  &--radio {
    display: flex;
    flex-direction: row-reverse;

    input {
      width: 2em;
      height: 2em;
      margin: 0.5em;
    }
  }

  &--text,
  &--password,
  &--email,
  &--number {
    input {
      appearance: none;
      background: none;
      border: none;
      border-bottom: 1px solid colors.$gray-very-light;
      cursor: auto;
      font-size: 1em;
      line-height: 32px;
      padding: 0.25em 2em 0.25em 0.25em;
      margin-bottom: 1;
      width: 100%;

      &::placeholder {
        color: colors.$gray-light;
      }

      // AutoFill @ Chrome
      // It keeps some yellow on hover/focus; for a11y
      &:-webkit-autofill {
        box-shadow: 0 0 0 30px colors.$white inset;
        -webkit-text-fill-color: colors.$green-water;
      }

      // Input Invalid @ FireFox
      &:invalid {
        box-shadow: none;
      }
    }
  }
}
